import React, { useEffect, useState, useCallback } from 'react';
import TopBar from '../layout/Topbar';
import axios from 'axios';
import './contentPage.css';
import { TbEdit, TbEye, TbReceipt, TbTrash, TbShare } from "react-icons/tb";
import { formatearFecha, truncateText } from '../../utils/utils';
import { ModalCancel, ModalVale, ModalTracking, ModalEditService } from '../layout/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const MyServices = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL?.replace(/\/?$/, "/") || "";
  const [progress, setProgress] = useState(false);
  // Modal
  const [showModalServiceDelete, setShowModalServiceDelete] = useState(false);
  const [showModalVale, setShowModalVale] = useState(false);
  const [showModalTracking, setShowModalTracking] = useState(false);
  const [showModalEditService, setShowModalEditService] = useState(false);

  const [selectedIdReserva, setSelectedIdReserva] = useState(null);
  const [token, setToken] = useState(null);
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const imageProfile = localStorage.getItem('fotourl');
  const idpersonal = localStorage.getItem('idpersonal');
  const idcliente = localStorage.getItem('idcliente');
  const key = localStorage.getItem('key');

  // Función modificada que acepta un parámetro para mostrar o no el loader
  const handleMyServices = useCallback(async (showLoader = false) => {
    if (showLoader) {
      setProgress(true);
    }
    try {
      const response = await axios.get(`${baseUrl}api/IntranetApp/Servicios`, {
        params: {
          idpersonal: idpersonal,
          idcliente: idcliente,
          condicion: 0,
          mes: 4,
          anio: 2024,
        },
        headers: {
          'Authorization': `Bearer ${key}`,
        }
      });
      if (response.data.estatus === 200) {
        setServices(response.data.AServicios);
      }
      if (showLoader) {
        setProgress(false);
      }
    } catch (error) {
      if (showLoader) {
        setProgress(false);
      }
    }
  }, [baseUrl, idpersonal, idcliente, key]);

  // Se ejecuta la carga inicial con el loader y luego se refresca cada 5s sin mostrar el loader
  useEffect(() => {
    // Primera carga: se muestra el loader
    handleMyServices(true);
    // Intervalo para refrescar la tabla cada 5 segundos sin mostrar el loader
    const intervalId = setInterval(() => {
      handleMyServices(false);
    }, 20000);
    return () => clearInterval(intervalId);
  }, [handleMyServices]);

  // Filtrado simple de servicios según el término de búsqueda
  const filteredServices = services.filter(service =>
    service.adicional.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.centrocostos.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.detallemotivo.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.direcciondestino.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.direccionorigen.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.estadoreserva.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.fechareserva.toString().includes(searchTerm) ||
    service.fechorenelpunto.toString().includes(searchTerm) ||
    service.fechorservicioenproceso.toString().includes(searchTerm) ||
    service.fechorserviciofinalizado.toString().includes(searchTerm) ||
    service.horareserva.toString().includes(searchTerm) ||
    service.idreserva.toString().includes(searchTerm) ||
    service.montofinalservicio.toString().includes(searchTerm) ||
    service.motivoregistro.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.personalsolicitante.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.tipopago.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.tiposervicio.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.idreserva.toString().includes(searchTerm)
  );

  const openModalEdit = (idreserva) => {
    setSelectedIdReserva(idreserva);
    setShowModalEditService(true);
  }

  const openModalDelete = (idreserva) => {
    setSelectedIdReserva(idreserva);
    setShowModalServiceDelete(true);
  };

  const openModalVale = (idreserva) => {
    setSelectedIdReserva(idreserva);
    setShowModalVale(true);
  };

  const openModalTracking = (token) => {
    setToken(token);
    setShowModalTracking(true);
  };

  const compartirPorWhatsApp = (url) => {
    const mensaje = encodeURIComponent('¡Hola! Te comparto mi viaje: ' + url);
    const enlaceWhatsApp = `https://wa.me/?text=${mensaje}`;
    window.open(enlaceWhatsApp, '_blank');
  };

  // Mapea los datos a exportar con los encabezados deseados
  const exportData = filteredServices.map(service => ({
    "ID": service.idreserva,
    "Servicio": service.tiposervicio,
    "Fecha": formatearFecha(service.fechareserva),
    "Hora": service.horareserva,
    "Estado": service.estadoreserva,
    "Solicitante": service.personalsolicitante || 'NO REGISTRA',
    "Origen": service.direccionorigen,
    "Adicional": service.adicional,
    "Destino": service.direcciondestino,
    "Pago": service.tipopago,
    "Monto": service.montofinalservicio,
    "Área": service.area || 'NO REGISTRA',
    "Centro costos": service.centrocostos || 'NO REGISTRA',
    "Motivo": service.motivoregistro || 'NO REGISTRA',
    "Detalle": service.detallemotivo || 'NO REGISTRA',
  }));

  return (
    <div className='page'>
      {progress && (
        <Box className='box-progress'>
          <CircularProgress color="success" size="3rem" />
        </Box>
      )}
      <TopBar
        title='Mis servicios'
        imageProfile={imageProfile}
        showDateSelect={false}
        showButtonAdd={false}
        showSearch={true}
        searchValue={searchTerm}
        onSearchChange={(value) => setSearchTerm(value)}
        exportData={exportData}          // Aquí se pasa la data para exportar
        exportFileName="mis_servicios.xlsx"  // Opcional: nombre del archivo
        exportSheetName="Mis servicios"      // Opcional: nombre de la hoja
      />
      <div className='content-page'>
        <table>
          <thead>
            <tr>
              <th>Acciones</th>
              <th>ID</th>
              <th>Servicio</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Estado</th>
              <th>Solicitante</th>
              <th>Origen</th>
              <th>Adicional</th>
              <th>Destino</th>
              <th>Pago</th>
              <th>Monto</th>
              <th>Área</th>
              <th>Centro costos</th>
              <th>Motivo</th>
              <th>Detalle</th>
            </tr>
          </thead>
          <tbody>
            {filteredServices.length > 0 ? (
              filteredServices.map((service) => (
                <tr key={service.idreserva}>
                  <td>
                    {/* {
                      service.idestado === 9 ||
                        service.idestado === 19 ||
                        service.idestado === 20 ||
                        service.idestado === 21 ?
                        < TbEdit className='TbEditCircle' onClick={() => openModalEdit(service.idreserva)} />
                        : null
                    } */}
                    <TbEye className='TbEye' onClick={() => openModalTracking(service.tokenvalida)} />
                    <TbReceipt className='TbReceipt' onClick={() => openModalVale(service.idreserva)} />
                    <TbShare
                      className='TbShare'
                      onClick={() => compartirPorWhatsApp(`https://intranet.taximonterrico.pe/tracking?key=${service.tokenvalida}`)}
                    />
                    {
                      service.idestado === 9 ||
                        service.idestado === 19 ||
                        service.idestado === 20 ||
                        service.idestado === 21 ?
                        <TbTrash className='TbTrash' onClick={() => openModalDelete(service.idreserva)} />
                        : null
                    }
                  </td>
                  <td>{service.idreserva}</td>
                  <td style={{ textTransform: 'uppercase' }}>{service.tiposervicio}</td>
                  <td>{formatearFecha(service.fechareserva)}</td>
                  <td>{service.horareserva}</td>
                  <td
                    style={{ fontWeight: '600', textAlign: 'center' }}
                    className={
                      service.idestado === 9 ? 'status-pendiente-text' :
                        service.idestado === 12 ? 'status-camino-al-servicio-text' :
                          service.idestado === 13 ? 'status-en-el-punto-text' :
                            service.idestado === 14 ? 'status-usuario-contactado-text' :
                              service.idestado === 15 ? 'status-en-proceso-text' :
                                service.idestado === 16 ? 'status-finalizado-text' :
                                  service.idestado === 17 ? 'status-desplazamiento-text' :
                                    service.idestado === 18 ? 'status-anulado-cancelado-text' :
                                      service.idestado === 19 ? 'status-preasignado-text' :
                                        service.idestado === 20 ? 'status-preasignado-text' :
                                          service.idestado === 21 ? 'status-preasignado-text' : ''
                    }
                  >
                    {service.estadoreserva}
                  </td>
                  <td className='text-align-table' style={{ textTransform: 'uppercase' }}>
                    {service.personalsolicitante || 'NO REGISTRA'}
                  </td>
                  <td className='text-align-table'>{truncateText(service.direccionorigen)}</td>
                  <td className='text-align-table'>{truncateText(service.adicional)}</td>
                  <td className='text-align-table'>{truncateText(service.direcciondestino)}</td>
                  <td>{service.tipopago}</td>
                  <td>{service.montofinalservicio}</td>
                  <td className='text-align-table'>{service.area || 'NO REGISTRA'}</td>
                  <td className='text-align-table'>{service.centrocostos || 'NO REGISTRA'}</td>
                  <td className='text-align-table'>{service.motivoregistro || 'NO REGISTRA'}</td>
                  <td className='text-align-table'>{service.detallemotivo || 'NO REGISTRA'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="16">No hay servicios registrados</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ModalTracking
        showModalTracking={showModalTracking}
        closeModal={() => setShowModalTracking(false)}
        token={token}
      />
      <ModalCancel
        showModalServiceDelete={showModalServiceDelete}
        closeModal={() => setShowModalServiceDelete(false)}
        idreserva={selectedIdReserva}
        apiprincipal={handleMyServices}
      />
      <ModalVale
        showModalVale={showModalVale}
        closeModal={() => setShowModalVale(false)}
        idreserva={selectedIdReserva}
      />
      <ModalEditService
        showModalEditService={showModalEditService}
        closeModal={() => setShowModalEditService(false)}
        idreserva={selectedIdReserva}
        apiprincipal={handleMyServices}
      />
    </div>
  );
};

export default MyServices;



// import React, { useEffect, useState, useCallback } from 'react';
// import TopBar from '../layout/Topbar';
// import axios from 'axios';
// import './contentPage.css';
// import { TbEye, TbReceipt, TbTrash, TbShare } from "react-icons/tb";
// import { formatearFecha, truncateText } from '../../utils/utils';
// import { ModalCancel, ModalVale, ModalTracking } from '../layout/Modal';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';

// const MyServices = () => {
//   const baseUrl = process.env.REACT_APP_BASE_URL?.replace(/\/?$/, "/") || "";
//   const [progress, setProgress] = useState(false);
//   const [showModalServiceDelete, setShowModalServiceDelete] = useState(false);
//   const [showModalVale, setShowModalVale] = useState(false);
//   const [showModalTracking, setShowModalTracking] = useState(false);
//   const [selectedIdReserva, setSelectedIdReserva] = useState(null);
//   const [token, setToken] = useState(null);
//   const [services, setServices] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   const imageProfile = localStorage.getItem('fotourl');
//   const idpersonal = localStorage.getItem('idpersonal');
//   const idcliente = localStorage.getItem('idcliente');
//   const key = localStorage.getItem('key');

//   const handleMyServices = useCallback(async () => {
//     setProgress(true);
//     try {
//       const response = await axios.get(`${baseUrl}/api/IntranetApp/Servicios`, {
//         params: {
//           idpersonal: idpersonal,
//           idcliente: idcliente,
//           condicion: 0,
//           mes: 4,
//           anio: 2024,
//         },
//         headers: {
//           'Authorization': `Bearer ${key}`,
//         }
//       });

//       if (response.data.estatus === 200) {
//         setServices(response.data.AServicios);
//       }
//       setProgress(false);
//     } catch (error) {
//       setProgress(false);
//     }
//   }, [baseUrl, idpersonal, idcliente, key]);

//   useEffect(() => {
//     handleMyServices();
//   }, [handleMyServices]);

//   // Filtrado simple de servicios según el término de búsqueda
//   const filteredServices = services.filter(service =>
//     service.adicional.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.centrocostos.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.detallemotivo.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.direcciondestino.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.direccionorigen.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.estadoreserva.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.fechareserva.toString().includes(searchTerm) ||
//     service.fechorenelpunto.toString().includes(searchTerm) ||
//     service.fechorservicioenproceso.toString().includes(searchTerm) ||
//     service.fechorserviciofinalizado.toString().includes(searchTerm) ||
//     service.horareserva.toString().includes(searchTerm) ||
//     service.idreserva.toString().includes(searchTerm) ||
//     service.montofinalservicio.toString().includes(searchTerm) ||
//     service.motivoregistro.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.personalsolicitante.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.tipopago.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.tiposervicio.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     service.idreserva.toString().includes(searchTerm)
//   );

//   const openModal = (idreserva) => {
//     setSelectedIdReserva(idreserva);
//     setShowModalServiceDelete(true);
//   };

//   const openModalVale = (idreserva) => {
//     setSelectedIdReserva(idreserva);
//     setShowModalVale(true);
//   };

//   const openModalTracking = (token) => {
//     setToken(token);
//     setShowModalTracking(true);
//   };

//   const compartirPorWhatsApp = (url) => {
//     const mensaje = encodeURIComponent('¡Hola! Te comparto mi viaje: ' + url);
//     const enlaceWhatsApp = `https://wa.me/?text=${mensaje}`;
//     window.open(enlaceWhatsApp, '_blank');
//   };

//   // Mapea los datos a exportar con los encabezados deseados
//   const exportData = filteredServices.map(service => ({
//     "ID": service.idreserva,
//     "Servicio": service.tiposervicio,
//     "Fecha": formatearFecha(service.fechareserva),
//     "Hora": service.horareserva,
//     "Estado": service.estadoreserva,
//     "Solicitante": service.personalsolicitante || 'NO REGISTRA',
//     "Origen": service.direccionorigen,
//     "Adicional": service.adicional,
//     "Destino": service.direcciondestino,
//     "Pago": service.tipopago,
//     "Monto": service.montofinalservicio,
//     "Área": service.area || 'NO REGISTRA',
//     "Centro costos": service.centrocostos || 'NO REGISTRA',
//     "Motivo": service.motivoregistro || 'NO REGISTRA',
//     "Detalle": service.detallemotivo || 'NO REGISTRA',
//   }));

//   return (
//     <div className='page'>
//       {progress && (
//         <Box className='box-progress'>
//           <CircularProgress color="success" size="3rem" />
//         </Box>
//       )}
//       <TopBar
//         title='Mis servicios'
//         imageProfile={imageProfile}
//         showDateSelect={false}
//         showButtonAdd={false}
//         showSearch={true}
//         searchValue={searchTerm}
//         onSearchChange={(value) => setSearchTerm(value)}
//         exportData={exportData}          // Aquí se pasa la data para exportar
//         exportFileName="mis_servicios.xlsx"  // Opcional: nombre del archivo
//         exportSheetName="Mis servicios"      // Opcional: nombre de la hoja
//       />
//       <div className='content-page'>
//         <table>
//           <thead>
//             <tr>
//               <th>Acciones</th>
//               <th>ID</th>
//               <th>Servicio</th>
//               <th>Fecha</th>
//               <th>Hora</th>
//               <th>Estado</th>
//               <th>Solicitante</th>
//               <th>Origen</th>
//               <th>Adicional</th>
//               <th>Destino</th>
//               <th>Pago</th>
//               <th>Monto</th>
//               <th>Área</th>
//               <th>Centro costos</th>
//               <th>Motivo</th>
//               <th>Detalle</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredServices.length > 0 ? (
//               filteredServices.map((service, index) => (
//                 <tr key={index}>
//                   <td>
//                     <TbEye className='TbEye' onClick={() => openModalTracking(service.tokenvalida)} />
//                     <TbReceipt className='TbReceipt' onClick={() => openModalVale(service.idreserva)} />
//                     <TbShare
//                       className='TbShare'
//                       onClick={() => compartirPorWhatsApp(`https://intranet.taximonterrico.pe/tracking?key=${service.tokenvalida}`)}
//                     />
//                     <TbTrash className='TbTrash' onClick={() => openModal(service.idreserva)} />
//                   </td>
//                   <td>{service.idreserva}</td>
//                   <td style={{ textTransform: 'uppercase' }}>{service.tiposervicio}</td>
//                   <td>{formatearFecha(service.fechareserva)}</td>
//                   <td>{service.horareserva}</td>
//                   <td
//                     style={{ fontWeight: '600', textAlign: 'center' }}
//                     className={
//                       service.idestado === 9 ? 'status-pendiente-text' :
//                         service.idestado === 12 ? 'status-camino-al-servicio-text' :
//                           service.idestado === 13 ? 'status-en-el-punto-text' :
//                             service.idestado === 14 ? 'status-usuario-contactado-text' :
//                               service.idestado === 15 ? 'status-en-proceso-text' :
//                                 service.idestado === 16 ? 'status-finalizado-text' :
//                                   service.idestado === 17 ? 'status-desplazamiento-text' :
//                                     service.idestado === 18 ? 'status-anulado-cancelado-text' :
//                                       service.idestado === 19 ? 'status-preasignado-text' :
//                                         service.idestado === 20 ? 'status-preasignado-text' :
//                                           service.idestado === 21 ? 'status-preasignado-text' : ''
//                     }
//                   >
//                     {service.estadoreserva}
//                   </td>
//                   <td className='text-align-table' style={{ textTransform: 'uppercase' }}>
//                     {service.personalsolicitante || 'NO REGISTRA'}
//                   </td>
//                   <td className='text-align-table'>{truncateText(service.direccionorigen)}</td>
//                   <td className='text-align-table'>{truncateText(service.adicional)}</td>
//                   <td className='text-align-table'>{truncateText(service.direcciondestino)}</td>
//                   <td>{service.tipopago}</td>
//                   <td>{service.montofinalservicio}</td>
//                   <td className='text-align-table'>{service.area || 'NO REGISTRA'}</td>
//                   <td className='text-align-table'>{service.centrocostos || 'NO REGISTRA'}</td>
//                   <td className='text-align-table'>{service.motivoregistro || 'NO REGISTRA'}</td>
//                   <td className='text-align-table'>{service.detallemotivo || 'NO REGISTRA'}</td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="16">No hay servicios registrados</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//       <ModalTracking
//         showModalTracking={showModalTracking}
//         closeModal={() => setShowModalTracking(false)}
//         token={token}
//       />
//       <ModalCancel
//         showModalServiceDelete={showModalServiceDelete}
//         closeModal={() => setShowModalServiceDelete(false)}
//         idreserva={selectedIdReserva}
//         apiprincipal={handleMyServices}
//       />
//       <ModalVale
//         showModalVale={showModalVale}
//         closeModal={() => setShowModalVale(false)}
//         idreserva={selectedIdReserva}
//       />
//     </div>
//   );
// };

// export default MyServices;