import React, { useState, useRef } from 'react';
import './login.css';
import axios from 'axios';
import Button from '../common/Button-login.js';
import logo from '../image/192x192_6.png';
import background from '../image/background Intranet.png';
import { TbArrowNarrowLeft } from "react-icons/tb";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import Input from '../common/Input.js';
// import background from '../image/login 1.png';

const Login = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL?.replace(/\/?$/, "/") || "";

    const [progress, setProgress] = useState(false);
    const [phone, setPhone] = useState('');
    // const [email, setEmail] = useState('');
    const [boxLogin, setBoxLogin] = useState(true);
    const [boxPhone, setBoxPhone] = useState(false);
    // const [boxEmail, setBoxEmail] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpValues, setOtpValues] = useState(Array(6).fill(""));
    const [buttonValidate, setButtonValidate] = useState(false);
    const [phoneMessage, setPhoneMessage] = useState({ text: '', type: '' });
    const otpRefs = useRef([]);

    const handleCode = async (phonemail, modo) => {
        setProgress(true)
        setOtpSent(true);
        setButtonValidate(true);

        const data = {
            idempresas: 0,
            telefonoemail: phonemail,
            modo: modo
        };

        try {
            const response = await axios.post(`${baseUrl}api/IntranetApp/Codigo`, data);
            // console.log(response.data);
            if (response.data.estatus === 200) {
                setPhoneMessage({ text: 'Código enviado correctamente.', type: 'success' });
            } else {
                setPhoneMessage({ text: response.data.message, type: 'error' });
            }
        } catch (error) {
            // console.log(error.response.data);
            setPhoneMessage({ text: error.response.data.message, type: 'error' });
        } finally {
            setProgress(false)
        }
    };

    const handleValidate = async (emailphone, modo) => {
        setProgress(true)
        const otp = otpValues.join("");
        // console.log("OTP entered:", otp);

        const data = {
            idempresas: 0,
            telefonoemail: emailphone,
            modo: modo, // 1 si es teléfono, 2 si es email
            codigo: otp,
            tfirebase: "", // en blanco si viene de Intranet
            plataforma: "Intranet" // Se puede enviar Android, iOS o Intranet
        };

        try {
            const validateResponse = await axios.post(`${baseUrl}api/IntranetApp/Validacion`, data);
            // console.log(validateResponse.data);
            if (validateResponse.data.estatus === 200) {
                const idcliente = validateResponse.data.idcliente;
                const idpersonal = validateResponse.data.idpersonal;
                const key = validateResponse.data.key;
                localStorage.setItem('idcliente', idcliente);
                localStorage.setItem('idpersonal', idpersonal);
                localStorage.setItem('key', key);
                setPhoneMessage({ text: '¡Validación exitosa!', type: 'success' });

                try {
                    const profileResponse = await axios.get(`${baseUrl}api/IntranetApp/Perfil`, {
                        params: {
                            idpersonal: idpersonal,
                            idcliente: idcliente,
                        },
                        headers: {
                            Authorization: `Bearer ${key}`,
                        }
                    });
                    if (profileResponse.data.estatus === 200) {
                        // console.log('Datos obtenidos!', profileResponse.data);
                        // Guarda los datos del usuario en localStorage
                        localStorage.setItem('nombrecomercial', profileResponse.data.OCliente.nombrecomercial);
                        localStorage.setItem('administrador', profileResponse.data.OPersonal.administrador);
                        localStorage.setItem('apellidos', profileResponse.data.OPersonal.apellidos);
                        localStorage.setItem('eliminado', profileResponse.data.OPersonal.eliminado);
                        localStorage.setItem('fotourl', profileResponse.data.OPersonal.fotourl);
                        localStorage.setItem('idcondicion', profileResponse.data.OPersonal.idcondicion);
                        localStorage.setItem('idpersonaljefe', profileResponse.data.OPersonal.idpersonaljefe);
                        localStorage.setItem('idvalidaservicio', profileResponse.data.OPersonal.idvalidaservicio);
                        localStorage.setItem('nombres', profileResponse.data.OPersonal.nombres);
                        localStorage.setItem('telefonoprincipal', profileResponse.data.OPersonal.telefonoprincipal);

                        window.location.href = './dashboard'
                    } else {
                        setPhoneMessage({ text: 'Error al obtener los datos del usuario.', type: 'error' });
                    }
                } catch (error) {
                    setPhoneMessage({ text: 'Error al obtener los datos del usuario.', type: 'error' });
                } finally {
                    setProgress(false)
                }

            } else {
                setPhoneMessage({ text: 'Error en la validación.', type: 'error' });
            }
        } catch (error) {
            // console.log(error.response.data);
            setPhoneMessage({ text: 'Error en la validación.', type: 'error' });
        } finally {
            setProgress(false)
        }
    };

    // // Abre el flujo correspondiente y oculta la pantalla inicial
    // const handleClickBox = (box) => {
    //     box(true);
    //     setBoxLogin(false);
    // };

    // Regresa al flujo anterior y resetea la validación OTP
    const handleClickBack = (box) => {
        box(false);
        setPhone('')
        setBoxLogin(true);
        setOtpSent(false);
        setOtpValues(Array(6).fill(""));
        setButtonValidate(false);
        setPhoneMessage('')
    };

    // Maneja el cambio en cada input OTP
    const handleOtpChange = (index, event) => {
        const value = event.target.value;
        if (value.length > 1) return;
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        if (value && index < 5) {
            otpRefs.current[index + 1].focus();
        }
    };

    // Permite que al borrar (Backspace) se mueva al input anterior si está vacío
    const handleOtpKeyDown = (index, event) => {
        if (event.key === "Backspace" && otpValues[index] === "") {
            if (index > 0) {
                otpRefs.current[index - 1].focus();
            }
        }
    };

    // Después de tus useState y useRef
    React.useEffect(() => {
        if (otpSent) {
            // Dale un pequeño delay para asegurarnos de que el input ya esté montado
            setTimeout(() => {
                otpRefs.current[0]?.focus();
            }, 0);
        }
    }, [otpSent]);


    return (
        <div className='login'>
            {progress && (
                <Box className='box-progress'>
                    <CircularProgress color="success" size="3rem" />
                </Box>
            )}
            <div className='background'>
                <img src={background} className='image-background' alt='background taxi monterrico' />
                <div className='filter-background'></div>
            </div>
            <div className='box-login'>
                <img src={logo} className='logo-taximonterrico' alt='logo taxi monterrico' />
                <h2>Bienvenido</h2>
                {otpSent && (
                    <TbArrowNarrowLeft onClick={() => handleClickBack(setBoxPhone)} />
                )}
                {!otpSent ? 'Ingresa el número de teléfono registrado' : 'Ingresa el código enviado a whatsapp'}
                {/* <h6>Ingresa el número de teléfono registrado</h6> */}
                {/* {boxLogin && (
                    <>
                    <h5>Elige el método de ingreso</h5>
                        <Button
                            label="Ingresar con número teléfono"
                            onClick={() => handleClickBox(setBoxPhone)}
                        />
                        <Button
                            label="Ingresar con correo"
                            onClick={() => handleClickBox(setBoxEmail)}
                        />
                    </>
                )} */}

                <div className='box-phone'>
                    <input
                        className='box-code'
                        type='phone'
                        placeholder="Ejm: 999777888"
                        disabled={otpSent}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && phone.trim()) {
                                handleCode(phone, 1);
                            }
                        }}
                    />
                    {phoneMessage.text && (
                        <p className={`message ${phoneMessage.type}`}>{phoneMessage.text}</p>
                    )}
                    {!otpSent && (
                        <Button
                            label="Enviar código"
                            onClick={() => handleCode(phone, 1)}
                        />
                    )}
                    {otpSent && (
                        <div className="otp-inputs"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && otpValues.join('').length === 6) {
                                    handleValidate(phone, 1);
                                }
                            }}>
                            {otpValues.map((val, index) => (
                                <input
                                    className='box-code'
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={val}
                                    onChange={(e) => handleOtpChange(index, e)}
                                    onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                    ref={el => otpRefs.current[index] = el}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {/* {boxEmail && (
                    <div className='box-email'>
                        <TbArrowNarrowLeft onClick={() => handleClickBack(setBoxEmail)} />
                        <p>Ingresa el correo electrónico registrado</p>
                        <Input
                            type='email'
                            placeholder="Ejm: demo@gmail.com"
                            disabled={otpSent}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {!otpSent && (
                            <Button
                                label="Enviar código"
                                onClick={() => handleCode(email, 2)}
                            />
                        )}
                        {otpSent && (
                            <div className="otp-inputs">
                                {otpValues.map((val, index) => (
                                    <input
                                        className='box-code'
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={val}
                                        onChange={(e) => handleOtpChange(index, e)}
                                        onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                        ref={el => otpRefs.current[index] = el}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )} */}
                {buttonValidate && (
                    <Button
                        label="Validar"
                        onClick={() => handleValidate(phone, 1)}
                    />
                )}
            </div>
        </div>
    );
};

export default Login;
