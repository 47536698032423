// Sidebar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.css';
import logoSidebar from '../image/192x192_2.png'
import { TbLayoutDashboardFilled, TbMapPinFilled, TbArrowBigLeftLinesFilled, TbRosetteDiscountCheckFilled, TbLayoutListFilled, TbUserFilled, TbBrandWhatsappFilled, TbFileFilled, TbFolderFilled, TbFiltersFilled, TbHelpSquareRoundedFilled } from "react-icons/tb";

const Sidebar = () => {

  const empresa = localStorage.getItem('nombrecomercial')
  const administrador = localStorage.getItem('administrador')

  const Logout = () => {
    localStorage.removeItem('Token')
    localStorage.removeItem('administrador')
    localStorage.removeItem('agentData')
    localStorage.removeItem('apellidos')
    localStorage.removeItem('eliminado')
    localStorage.removeItem('fotourl')
    localStorage.removeItem('idAgent')
    localStorage.removeItem('idBusiness')
    localStorage.removeItem('idcliente')
    localStorage.removeItem('idcondicion')
    localStorage.removeItem('idpersonal')
    localStorage.removeItem('idpersonaljefe')
    localStorage.removeItem('idvalidaservicio')
    localStorage.removeItem('key')
    localStorage.removeItem('loglevel')
    localStorage.removeItem('nombrecomercial')
    localStorage.removeItem('nombres')
    localStorage.removeItem('telefonoprincipal')

    window.location.href = '/'
  }

  return (
    <nav className="sidebar">
      <div>
        <div className="sidebar-logo">
          {/* Puedes incluir aquí tu logo */}
          <img src={logoSidebar} alt="Logo Intranet" />
        </div>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => isActive ? "active" : ""}
            >
              <TbLayoutDashboardFilled />
              Panel
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/request"
              className={({ isActive }) => isActive ? "active" : ""}
            >
              <TbMapPinFilled />
              Solicitar
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/myservices"
              className={({ isActive }) => isActive ? "active" : ""}
            >
              <TbLayoutListFilled />
              Mis servicios
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/history"
              className={({ isActive }) => isActive ? "active" : ""}
            >
              <TbFolderFilled />
              Historial
            </NavLink>
          </li>
          {administrador === 'true' && (
            <>
              <li className='line-sidebar'></li>
              <li>
                <NavLink
                  to="/valide"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbRosetteDiscountCheckFilled />
                  Valida solicitud
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/personalservices"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbLayoutListFilled />
                  Personal serv.
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/personalhistory"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbFolderFilled />
                  Personal hist.
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/personal"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbUserFilled />
                  Personal
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/costcenter"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbFiltersFilled />
                  Centros costos
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/areas"
                  className={({ isActive }) => isActive ? "active" : ""}
                >
                  <TbFiltersFilled />
                  Áreas
                </NavLink>
              </li>
            </>
          )}
          <li className='line-sidebar'></li>
          <a href="https://wa.me/+51989063650" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <li className='sidebar-support'>
              <TbBrandWhatsappFilled />
              Whatsapp
            </li>
          </a>

          <a href="https://taximonterrico.com/faq" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <li className='sidebar-support'>
              <TbHelpSquareRoundedFilled />
              Mesa de ayuda
            </li>
          </a>

          {/* <a href="./Manual_Intranet.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <li className='sidebar-support'>
            <TbFileFilled />
            Manual
          </li>
        </a> */}

          <li className='sidebar-support' onClick={Logout}>
            <TbArrowBigLeftLinesFilled />
            Cerrar sesión
          </li>
          {/* Agrega más enlaces según tus rutas */}
        </ul>
      </div>
      <div className='business-admin'>
        <p className='business'>{empresa}</p>
        <p className='isAdmin'>{administrador === true ? 'Administrador' : 'Usuario'}</p>
        <p style={{ textAlign:'center', fontWeight:'600', marginTop:'8px', fontSize:'12px' }}>Versión 1.0.5</p>
      </div>
    </nav>
  );
};

export default Sidebar;
