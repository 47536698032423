import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import logoNegativo from '../image/192x192_2-negativo.png'
import logo from '../image/192x192_6.png'
import './fotocheck.css'

const Fotocheck = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL?.replace(/\/?$/, "/") || "";

  const [fotocheck, setFotocheck] = useState({});

  const currentUrl = new URL(window.location.href);
  const key = currentUrl.searchParams.get('key');

  const handleFotocheck = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}api/Asociados/Fotocheckv?key=${key}`)

      if (response.data.estatus === 200) {
        setFotocheck(response.data.OFotocheckv)
        // console.log(response.data)
      } else {
        // console.log(response.data.message)
      }
    } catch (error) {
      // console.log(error.response.data.message)
    }
  }, [baseUrl, key])

  useEffect(() => {
    handleFotocheck()
  }, [handleFotocheck])

  return (
    <>
      {fotocheck && (
        <div className='fotocheck'>
          <div className='fotocheck-container-anverso'>
            <img className='fotocheck-logo' src={logoNegativo} alt='Imagen logo'></img>
            <div className='box-name-driver'>
              <p className='nombrechofer'>{fotocheck.nombres}</p>
              <p className='apellidochofer'>{fotocheck.apellidos}</p>
            </div>
            <div className='content-image-driver'>
              <img src={fotocheck.imaasoc} alt="Fotocheck anverso" className="fotocheck-image" />
            </div>
            <div className="box-content-name">
              <div className="box-content-name-text">
                <p className="ndni">DOC. {fotocheck.ndni}</p>
                <p className='v-text'>Venc. {fotocheck.fvencimiento}</p>
              </div>
            </div>
          </div>
          <div className='fotocheck-container-reverso'>
            <div className='fotocheck-footer'>
              <img src={logo} alt="Fotocheck anverso" className="fotocheck-logo-reverso" />
              <h1>Términos y condiciones</h1>
              <p>Presentación a solicitud del <br />
                personal de seguridad. <br />
                Uso inadecuado resulta en <br />
                sanciones legales
              </p>
              <p>(01) 611-5555 | 924 879 402 <br />
                Av. Prolongación Iquitos 2299, <br />
                Lince, Lima - Perú
              </p>
            </div>
            <img src={fotocheck.qrUrl} alt="QR" className="fotocheck-qr"></img>
          </div>
        </div>
      )}
    </>
  )
}

export default Fotocheck