import React from 'react';
import './input.css';

const Input = ({ type = 'text', placeholder = '', value, onChange, checked, className = '', id = '', maxlength, disabled }) => (
    <input
        className={`input ${className}`}
        type={type}
        placeholder={placeholder}
        value={type === 'checkbox' ? undefined : value} // Evitar 'value' en checkbox
        checked={type === 'checkbox' ? checked : undefined} // Añadir 'checked' si es checkbox
        onChange={onChange}
        id={id}
        disabled={disabled}
        maxLength={maxlength}
    />
);

export default Input;
