import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import GoogleMaps from '../layout/GoogleMaps';
import { IoFlagOutline, IoFlag } from "react-icons/io5";
import { formatearFecha } from '../../utils/utils'
import './tracking.css'
import background from '../image/background Intranet.png';

const Tracking = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL?.replace(/\/?$/, "/") || "";

    const [initialLoad, setInitialLoad] = useState(true);
    const [oServicio, setOServicio] = useState({});
    const [aRuta, setARuta] = useState([]);
    const [oAsociado, setOAsociado] = useState({});
    const [oVehiculo, setOVehiculo] = useState({});;
    const [asociadoMarker, setAsociadoMarker] = useState(null);

    const currentUrl = new URL(window.location.href);
    const token = currentUrl.searchParams.get('key');

    const obtenerTracking = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}api/IntranetApp/Tracking`, {
                params: { key: token }
            });

            if (response.data.estatus === 200) {
                setOServicio(response.data.OServicio);
                setARuta(response.data.ARuta);
                setOAsociado(response.data.OAsociados);
                setOVehiculo(response.data.OVehiculo);
                // setARecorridogps(response.data.ARecorridogps);

                const { latasoc, latlong, angulo } = response.data.OAsociadosu;
                if (latasoc !== 0 && latlong !== 0) {
                    setAsociadoMarker({
                        lat: latasoc,
                        lng: latlong,
                        type: 'image',
                        angle: angulo,
                        id: 'asociado-current'
                    });
                }
                // Si idestado es 16, 17 o 18 se detiene el intervalo
                if (
                    !response.data.OServicio ||
                    !response.data.OServicio.idestado ||
                    [16, 17, 18].includes(response.data.OServicio.idestado)
                ) {
                    return { success: true, stopInterval: true };
                }
                return { success: true, stopInterval: false };
            } else {
                return { success: false, stopInterval: false };
            }
        } catch (error) {
            console.error(error);
            return { success: false, stopInterval: false };
        } finally {
            if (initialLoad) {
                // setProgress(false);
                setInitialLoad(false);
            }
        }
    }, [baseUrl, token, initialLoad]);

    useEffect(() => {
        let intervalId;

        if (token) {
            // Llamada inicial
            obtenerTracking().then((result) => {
                // Si la respuesta es exitosa y stopInterval es false, iniciamos el intervalo
                if (result.success && !result.stopInterval) {
                    intervalId = setInterval(() => {
                        obtenerTracking().then((res) => {
                            // Si en alguna llamada se cumple que idestado es 16, 17 o 18, se limpia el intervalo
                            if (res.stopInterval) {
                                clearInterval(intervalId);
                            }
                        });
                    }, 20000);
                }
            });
        }

        return () => clearInterval(intervalId);
    }, [token, obtenerTracking]);

    const rutaMarkers = aRuta.map((ruta) => ({
        lat: ruta.latitude,
        lng: ruta.longitude,
        type: 'circle',
        number: ruta.item,
        direccion: ruta.direccion,
        id: `ruta-${ruta.item}`,
    }));

    const markers = asociadoMarker ? [...rutaMarkers, asociadoMarker] : rutaMarkers;

    return (
        <div className='modal'>
            {Object.keys(oServicio).length > 0 ? (
                <div className='box-tracking-web'>
                    {/* Se usa handleCloseModal para limpiar los estados antes de cerrar */}
                    <div className='content-data-tracking'>
                        {oServicio && (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <h3
                                    style={{ fontWeight: '600', textAlign: 'center' }}
                                    className={
                                        oServicio.idestado === 9 ? 'status-pendiente' :
                                            oServicio.idestado === 12 ? 'status-camino-al-servicio' :
                                                oServicio.idestado === 13 ? 'status-en-el-punto' :
                                                    oServicio.idestado === 14 ? 'status-usuario-contactado' :
                                                        oServicio.idestado === 15 ? 'status-en-proceso' :
                                                            oServicio.idestado === 19 ? 'status-preasignado' :
                                                                oServicio.idestado === 20 ? 'status-preasignado' :
                                                                    oServicio.idestado === 21 ? 'status-preasignado' : ''
                                    }
                                >
                                    {oServicio.estadoreserva}
                                </h3>
                                <h5 style={{ fontWeight: '600', textAlign: 'center' }}>#{oServicio.idreserva}</h5>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h6 style={{ fontWeight: '600' }}>{formatearFecha(oServicio.fechareserva)}</h6>
                                    <h6 style={{ fontWeight: '600' }}>{oServicio.horareserva}</h6>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h6 style={{ fontWeight: '600' }}>{oServicio.tipopago}</h6>
                                    {/* <h6 style={{ fontWeight: '600' }}>{oServicio.montofinalservicio}</h6> */}
                                </div>
                            </div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} >
                            <h6 style={{ fontWeight: '600' }}>Detalle ruta</h6>
                            {aRuta.map((ruta) => (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} key={ruta.item}>
                                    {ruta.item === 1 ? <p><IoFlagOutline /> Origen</p> : <p><IoFlag /> Destino</p>}
                                    <p>{ruta.direccion}</p>
                                </div>
                            ))}
                        </div>
                        {(oServicio.idestado === 12 || oServicio.idestado === 13 || oServicio.idestado === 14 || oServicio.idestado === 15 || oServicio.idestado === 19) && (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                {oAsociado.nombres !== '' ? (
                                    <>
                                        <h6 style={{ fontWeight: '600' }}>Detalle conductor</h6>
                                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                            <img src={oAsociado.imaasoc} alt='Conductor' className='imagen-conductor' />
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <h6 style={{ fontWeight: '600' }}>{oAsociado.apellidos} {oAsociado.nombres}</h6>
                                                <p>{oAsociado.telefonop}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : ''}
                                {oVehiculo.marca !== '' ? (
                                    <>
                                        <h6 style={{ fontWeight: '600' }}>Detalle vehículo</h6>
                                        <div>
                                            <h5>{oVehiculo.nplaca}</h5>
                                            <p>{oVehiculo.marca} {oVehiculo.modelo} {oVehiculo.color} {oVehiculo.año}</p>
                                            {/* <p>{oVehiculo.color} {oVehiculo.año}</p> */}
                                        </div>
                                    </>
                                ) : ''}
                            </div>
                        )}
                    </div>
                    <GoogleMaps markers={markers} />
                </div>
            ) : (    // CONTENIDO CUANDO oServicio está vacío
                <div className="no-tracking-data">
                    <img src={background} className='img-404' alt='Sin contenido'></img>
                    <div className='texto-404'>
                        <h1>No hay datos de servicio disponibles.</h1>
                        <h4>Verifica que tengas una reserva activa o vuelve a intentarlo más tarde.</h4>
                    </div>

                </div>
            )}
        </div>
    );
}

export default Tracking